@charset 'utf-8';
@import 'settings';

html {
    [class^="row element"].frame-threecolumns .calendarize {
        display: flex;
        flex-direction: row;

        .large-12 {
            min-width: 33%;
            flex: 1 1;
            max-width: none;

            ul.large-12 {
                max-width: 100%;

                .event-list {
                    max-width: 90%;

                    .large-3,
                    .large-9 {
                        width: 100%;
                        max-width: 100%;
                        padding-right: 0;

                        img {
                            width: 100%;
                        }
                    }
                }
            }
        }
    }

    [class^="row element"] {
        //padding: 1rem 0;
        z-index: -10;
        // Kalender Extension
        .frame-box-line-bottom .calendarize {
            border-bottom: 1px solid $lightgray;
            margin-bottom: 2.5rem;
        }

        [class*="-6"] .calendarize {
            border: none;

            .calendarize-item .event-list {
                max-width: 93%;

                [class*="-3"],
                [class*="-9"],
                a {
                    width: 100%;
                    max-width: 100%;
                    float: none;
                    padding: 0;
                }

            }
        }

        .calendarize {
            @include textlists();

            .calendarize-item {
                width: 100%;

                h3,
                p {
                    margin: 0;
                    padding: 0;
                    line-height: normal;
                    color: $darkgray;
                    text-decoration: none;
                }

                h3 {
                    border: none;
                }

                ul {
                    li {
                        margin-bottom: 1.75rem;
                        display: flex;
                        flex-direction: row;

                        a::before {
                            display: none;
                        }

                        .event-list {
                            flex: 3 1 auto;
                        }

                        .event-list,
                        .more {
                            display: inline-block;
                            width: 96%;
                            max-width: 96%;
                        }
                    }

                    li::before {
                        border: 2px solid $darkgray;
                        border-radius: 100%;
                        width: 15px;
                        height: 15px;
                        content: " ";
                        display: inline-block;
                        margin-right: 1.125rem;
                        margin-top: .5rem;
                        vertical-align: top;
                        flex: 1 0 auto;
                        flex-grow: unset;
                    }
                }

                a {
                    margin-left: 0;
                    padding-left: 0;
                    display: block;

                    p.font-normal {
                        font-family: $body-font-family;
                        text-transform: none;
                    }

                    p.font-light {
                        font-family: $body-font-familyLight;
                        text-transform: none;
                    }

                    p.font-light::after {
                        content: "›››";
                        padding: 0 1%;
                        display: inline-block;
                    }
                }

                [class*="-9"] {
                    max-width: 100%;
                    width: 100%;
                }

                a[class*="-3"] + a[class*="-9"] {
                    max-width: 75%;
                }

                a:hover p.font-light::after {
                    color: $darkblue;
                }

                a::after {
                    display: none;
                }

                a + .media-body {
                    flex: 0 0 66.66%;
                    max-width: 66.66%;
                }

                a.image {
                    //margin-left: -.9375rem;
                }
            }

            .pagination::before {
                display: none;
            }

            .text-center {
                width: 100%;
                padding-left: .9375rem;
                padding-right: .9375rem;
                text-align: left;

                .pagination {
                    display: block;
                    margin: 0 auto;

                    li {
                        a::before {
                            display: none;
                        }

                        a {
                            background: none;
                        }
                    }
                }
            }

            .downloads {
                a {
                    hyphens: auto;
                }
            }

            .panel-default {
                .article-header {
                    box-sizing: border-box;
                    max-width: 1170px;
                    width: 100%;
                    padding-bottom: 3rem;
                    margin-bottom: 4rem;
                    border-bottom: 1px solid $lightgray;
                    display: table;

                    .frame-layout-1 {
                        display: table-cell;
                        max-width: 55%;
                    }

                    .frame-layout-1 + .header {
                        margin-left: 1rem;
                        display: table-cell;
                        vertical-align: middle;
                        max-width: 45%;
                    }
                }

                .row .text {
                    // mixin für vereinheitlichung der allgemeinen Listendarstellung im Text
                    @include textlists();
                }

            }
        }

        .grid-x.caldetails {
            margin-left: -.9375rem;
            margin-right: -.9375rem;

            .cell {
                margin: 0;
            }
        }
    }

}

/** ------------- https://css-tricks.com/snippets/css/media-queries-for-standard-devices/ ------------- **/
/** gültig für alle Geräte **/
/* -------------------------------------------- */
/* iPhone 2G-4S in portrait & landscape */
@media only screen and (max-device-width: 480px) {
    html {
        body {
            .breadcrumbsline {
                padding: 0 1.5rem;
            }

            .initScrollToTop + .breadcrumbsline {
                padding-top: 1rem;
            }

            [class^="row element"] {
                h3 {
                    font-size: 1.25rem;
                }

                [class*="-6"] .calendarize .calendarize-item .event-list {
                    max-width: 87%;
                }

                .calendarize {
                    .calendarize-item {
                        .event-list {
                            max-width: 87%;

                            .image,
                            a.more[class*="-9"] {
                                width: 100%;
                                max-width: 100%;
                            }
                        }

                        ul li {
                            margin-bottom: 0;
                        }
                    }

                    .panel {
                        .article-header {
                            display: block;
                            padding-bottom: 0;

                            .frame-layout-1,
                            .header {
                                display: block;
                                width: 100%;
                                max-width: 100%;
                            }

                            .header .small-12 {
                                padding: 2rem 0 1rem;
                            }
                        }
                    }

                    .dl-horizontal {
                        .medium-1 {
                            flex: 0 0 25%;
                            max-width: 25%;
                        }

                        .medium-2 {
                            flex: 0 0 75%;
                            max-width: 75%;
                        }
                    }
                }

                .grid-x.caldetails {
                    margin-left: -.75rem;
                    margin-right: -.75rem;
                }
            }
        }
    }
}

/* iPhone 2G-4S in landscape */
@media only screen and (min-device-width: 320px) and (max-device-width: 480px) and (orientation: landscape) {
}

/* iPhone 2G-4S in portrait */
@media only screen and (min-device-width: 320px) and (max-device-width: 480px) and (orientation: portrait) {
}

/* -------------------------------------------- */
/* iPhone 5 Media Queries */
/* iPhone 5 & 5S in portrait & landscape */
@media only screen and (min-device-width: 320px) and (max-width: 568px) {
    html {
        body {
            [class^="row element"] {
                h3 {
                    font-size: 1.25rem;
                }

                [class*="-6"] .calendarize .calendarize-item .event-list {
                    max-width: 87%;
                }

                .calendarize {
                    .calendarize-item {
                        .event-list {
                            max-width: 87%;

                            .image,
                            a.more[class*="-9"] {
                                width: 100%;
                                max-width: 100%;
                            }
                        }

                        ul li {
                            margin-bottom: 0;
                        }
                    }

                    .panel {
                        .dl-horizontal {
                            .medium-1 {
                                flex: 0 0 25%;
                                max-width: 25%;
                            }

                            .medium-2 {
                                flex: 0 0 75%;
                                max-width: 75%;
                            }
                        }
                    }
                }

                .grid-x.caldetails {
                    margin-left: -.75rem;
                    margin-right: -.75rem;
                }

            }
        }
    }
}

/* iPhone 5 & 5S in landscape */
@media only screen and (min-device-width: 320px) and (max-device-width: 568px) and (orientation: landscape) and (-webkit-min-device-pixel-ratio: 2.0) {
}

/* iPhone 5 & 5S in portrait */
@media only screen and (min-device-width: 320px) and (max-device-width: 568px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 2.0) {
}

/* -------------------------------------------- */
/* iPhone 6 Media Queries */
/* iPhone 6 in portrait & landscape */
@media only screen and (min-device-width: 375px) and (max-device-width: 667px) {
    html {
        body {
            [class^="row element"] {
                h3 {
                    font-size: 1.25rem;
                }

                [class*="-6"] .calendarize .calendarize-item .event-list {
                    max-width: 87%;
                }

                .calendarize {
                    .calendarize-item {
                        .event-list {
                            max-width: 87%;

                            .image,
                            a.more[class*="-9"] {
                                width: 100%;
                                max-width: 100%;
                            }
                        }

                        ul li {
                            margin-bottom: 0;
                        }
                    }

                    .panel {
                        .dl-horizontal {
                            .medium-1 {
                                flex: 0 0 25%;
                                max-width: 25%;
                            }

                            .medium-2 {
                                flex: 0 0 75%;
                                max-width: 75%;
                            }
                        }
                    }
                }

                .grid-x.caldetails {
                    margin-left: -.75rem;
                    margin-right: -.75rem;
                }

            }
        }
    }
}

/* iPhone 6 in landscape */
@media only screen and (min-device-width: 375px) and (max-device-width: 667px) and (orientation: landscape) {
}

/* iPhone 6 in portrait */
@media only screen and (min-device-width: 375px) and (max-device-width: 667px) and (orientation: portrait) {
}

/* iPhone 6 Plus in portrait & landscape */
@media only screen and (min-device-width: 414px) and (max-device-width: 736px) {
    html {
        body {
            [class^="row element"] {
                h3 {
                    font-size: 1.25rem;
                }

                [class*="-6"] .calendarize .calendarize-item .event-list {
                    max-width: 87%;
                }

                .calendarize {
                    .calendarize-item {
                        .event-list {
                            max-width: 87%;

                            .image,
                            a.more[class*="-9"] {
                                width: 100%;
                                max-width: 100%;
                            }
                        }

                        ul li {
                            margin-bottom: 0;
                        }
                    }

                    .panel {
                        .dl-horizontal {
                            .medium-1 {
                                flex: 0 0 16.6666%;
                                max-width: 16.6666%;
                            }

                            .medium-2 {
                                flex: 0 0 33.3333%;
                                max-width: 33.3333%;
                            }
                        }
                    }
                }

                .grid-x.caldetails {
                    margin-left: -.75rem;
                    margin-right: -.75rem;
                }

            }
        }
    }
}

/* iPhone 6 Plus in landscape */
@media only screen and (min-device-width: 414px) and (max-device-width: 736px) and (orientation: landscape) {
}

/* iPhone 6 Plus in portrait */
@media only screen and (min-device-width: 414px) and (max-device-width: 736px) and (orientation: portrait) {
}

/* -------------------------------------------- */
/* generell 768 x 1024 portrait & landscape */
@media only screen and (min-width: 768px) and (max-width: 1024px) {
    html {
        body {
            [class^="row element"] {
                h3 {
                    font-size: 1.25rem;
                }

                [class*="-6"] .calendarize .calendarize-item .event-list {
                    max-width: 87%;
                }

                .calendarize {
                    .calendarize-item {
                        .event-list {
                            max-width: 87%;

                            .image,
                            a.more[class*="-9"] {
                                width: 100%;
                                max-width: 100%;
                            }
                        }

                        ul li {
                            margin-bottom: 0;
                        }
                    }

                    .panel {
                        .dl-horizontal {
                            .medium-1 {
                                flex: 0 0 16.6666%;
                                max-width: 16.6666%;
                            }

                            .medium-2 {
                                flex: 0 0 33.3333%;
                                max-width: 33.3333%;
                            }
                        }
                    }
                }
            }
        }
    }
}

@media only screen and (min-width: 737px) and (max-width: 1024px) and (orientation: portrait) {
}

@media only screen and (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
}

/* -------------------------------------------- */
/* iPad mini 1 & 2 in portrait & landscape */
@media only screen and (min-width: 768px) and (max-width: 1024px) and (-webkit-min-device-pixel-ratio: 1) {
}

/* iPad mini in landscape */
@media only screen and (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) and (-webkit-min-device-pixel-ratio: 1) {
}

/* iPad mini in portrait */
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 1) {
}

/* -------------------------------------------- */
/* iPad mini 3 in portrait & landscape */
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (-webkit-min-device-pixel-ratio: 2) {
}

/* iPad mini in landscape */
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: landscape) and (-webkit-min-device-pixel-ratio: 2) {
}

/* iPad mini in portrait */
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 2) {
}

/* -------------------------------------------- */
/* iPad in portrait & landscape */
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) {
}

/* iPad in landscape */
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: landscape) {
}

/* iPad in portrait */
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) {
}

/* -------------------------------------------- */
/* iPad 1 & 2 in portrait & landscape */
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (-webkit-min-device-pixel-ratio: 1) {
}

/* iPad 1 & 2 in landscape */
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: landscape) and (-webkit-min-device-pixel-ratio: 1) {
}

/* iPad 1 & 2 in portrait */
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 1) {
}

/* -------------------------------------------- */
/* iPad 3 & 4 in portrait & landscape */
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (-webkit-min-device-pixel-ratio: 2) {
}

/* iPad 3 & 4 in landscape */
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: landscape) and (-webkit-min-device-pixel-ratio: 2) {
}

/* iPad 3 & 4 in portrait */
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 2) {
}

/* -------------------------------------------- */
/* iPad Pro in portrait & landscape */
/*only screen and (width: 678px) and (height: 1024px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 2)/* and (device-width: 1366px)  /*and (-webkit-min-device-pixel-ratio: 2)*/
@media only screen and (min-height: 981px) and (max-height: 1366px) and (min-width: 981px) and (max-width: 1366px) and (-webkit-min-device-pixel-ratio: 2), only screen and (min-height: 1024px) and (max-height: 1366px) and (min-width: 1024px) and (max-width: 1366px) and (-webkit-min-device-pixel-ratio: 2) {
}

/* iPad Pro Portrait */
/*and (-webkit-min-device-pixel-ratio: 2)*/
@media only screen and (width: 1024px) and (height: 1366px) and (orientation: portrait) {
}

/* iPad Pro Landscape */
/*and (-webkit-min-device-pixel-ratio: 2)*/
@media only screen and (height: 1024px) and (width: 1366px) and (orientation: landscape) {
}

/* iPad Pro Teilscreen */
/*and (-webkit-min-device-pixel-ratio: 2)*/
@media only screen and (width: 981px) and (height: 1024px) {
}

/* später aktivieren */
/* iPad Pro Teilscreen */
/* and (device-width: 1366px)  /*and (-webkit-min-device-pixel-ratio: 2)*/
@media only screen and (width: 678px) and (height: 1024px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 2) {
}

/* andere hochauflösende Displays */
@media screen and (-webkit-min-device-pixel-ratio: 3.0) and (min-width: 800px) and (max-width: 1280px) {
}

@media screen and (-webkit-min-device-pixel-ratio: 3.0) and (min-width: 800px) and (max-width: 1280px) and (orientation: landscape) {
}

/* ----------- Galaxy Tab 10.1 ----------- */
/* Portrait and Landscape */
@media only screen and (min-width: 800px) and (max-width: 1280px) {
}

/* Portrait */
@media only screen and (max-width: 800px) and (orientation: portrait) {
}

/* Landscape */
@media only screen and (max-width: 1280px) and (orientation: landscape) {
}

/* ----------- Asus Nexus 7 ----------- */
/* Portrait and Landscape */
@media only screen and (device-width: 601px) and (device-height: 906px) and (-webkit-min-device-pixel-ratio: 1.331) and (-webkit-max-device-pixel-ratio: 1.332) {
}

/* Portrait */
@media only screen and (device-width: 601px) and (device-height: 906px) and (-webkit-min-device-pixel-ratio: 1.331) and (-webkit-max-device-pixel-ratio: 1.332) and (orientation: portrait) {
}

/* Landscape */
@media only screen and (device-width: 601px) and (device-height: 906px) and (-webkit-min-device-pixel-ratio: 1.331) and (-webkit-max-device-pixel-ratio: 1.332) and (orientation: landscape) {
}

/* ----------- Kindle Fire HD 7" ----------- */
/* Portrait and Landscape */
@media only screen and (min-width: 800px) and (max-width: 1280px) and (-webkit-min-device-pixel-ratio: 1.5) {
}

/* Portrait */
@media only screen and (min-width: 800px) and (max-width: 1280px) and (-webkit-min-device-pixel-ratio: 1.5) and (orientation: portrait) {
}

/* Landscape */
@media only screen and (min-width: 800px) and (max-width: 1280px) and (-webkit-min-device-pixel-ratio: 1.5) and (orientation: landscape) {
}

/* ----------- Kindle Fire HD 8.9" ----------- */
/* Portrait and Landscape */
@media only screen and (min-width: 1200px) and (max-width: 1600px) and (-webkit-min-device-pixel-ratio: 1.5) {
}

/* Portrait */
@media only screen and (min-width: 1200px) and (max-width: 1600px) and (-webkit-min-device-pixel-ratio: 1.5) and (orientation: portrait) {
}

/* Landscape */
@media only screen and (min-width: 1200px) and (max-width: 1600px) and (-webkit-min-device-pixel-ratio: 1.5) and (orientation: landscape) {
}

@media only screen and (min-width: 1850px) {
}

@media only screen and (min-aspect-ratio: 1/1) and (max-aspect-ratio: 16/10) {
}
