// mixin für vereinheitlichung der allgemeinen Listendarstellung im Text
@mixin textlists() {
	ol,
	ul {
		margin-left: 0;

		li {
			font-size: 1.125rem;
			padding: 0;
			margin: 0 0 0 1rem;
		}
	}
	ol li {
		margin-left: 1.25rem;
	}

	ul {
		list-style: none;

		li::before {
			content: "-";
			margin-left: -1rem;
			padding-right: 1rem;
		}
	}
}

@mixin pagination() {
	.f3-widget-paginator {
		display: block;
		margin: 3rem auto 0;
		//width: 100%;

		li {
			display: inline-block;
			text-align: center;
			line-height: normal;
			padding: .5em;

			a {
				background: none;
			}

			a::after,
			a::before {
				content: "";
				display: none;
			}
		}

		li.current {
			color: $darkblue;
			font-family: $body-font-familyBold;
			line-height: normal;
			margin: 0;
			padding: .5em;

			a {
				color: $darkblue;
				font-family: $body-font-familyBold;
			}
		}

		li.previous a::before {
			content: "‹‹‹";
			padding: 0;
			display: inline;
			border: none;
			margin: 0 1rem 0 0;
			color: $darkblue;
		}

		li.next a::after {
			content: "›››";
			padding: 0;
			display: inline;
			border: none;
			margin: 0 0 0 1rem;
			color: $darkblue;
		}
	}
}

/*
@mixin lists() {
    ul {
        list-style: none;
        margin-left: 0;

        li {
            list-style: none;
            margin-bottom: 1.25rem;
            border: 1px solid red;

            a {
                //margin-left: -1.5rem;
                font-size: 1.125rem;
                font-family: $body-font-familyBold;
                text-transform: uppercase;
                color: $darkgray;

                span {
                    width: 75%;
                    max-width: 75%;
                    word-wrap: anywhere;
                    display: inline-block;
                    color: $darkgray;
                    line-height: normal;
                }
            }

            a:focus,
            a:focus span,
            a:hover,
            a:hover span {
                color: $darkblue;
            }

            a::before {
                border: 2px solid $darkgray;
                border-radius: 100%;
                width: 15px;
                height: 15px;
                content: " ";
                display: inline-block;
                margin-right: 1.125rem;
                margin-top: .5rem;
                vertical-align: top;
            }

            a::after {
                vertical-align: top;
            }
        }
    }
}
*/
